/**
 * Cleans content by:
 * - Replacing curly/smart quotes with normal quotes
 * - Removing invisible Unicode characters (zero-width spaces)
 * - Fixing non-standard text encodings
 *
 * @param {string} content - The input content to clean
 * @returns {string} - Cleaned content
 */
export const replaceContent = content => {
  if (typeof content !== "string") {
    return content == null ? "" : String(content); // Convert to string, or return empty if null/undefined
  }

  // Unicode Zero Width Characters
  // eslint-disable-next-line no-misleading-character-class
  const zeroWidthRegex = /[\u200B\u200C\u200D\u2060\uFEFF]/g;

  // Debugging: Log before and after removing zero-width characters
  return (
    content
      .replace(zeroWidthRegex, "")
      .replace(/[\u201C\u201D]/g, '"') // Replace curly double quotes
      .replace(/[\u2018\u2019]/g, "'") // Replace curly single quotes
      .replace(/\u00A0/g, " ") // non-breaking space
      // eslint-disable-next-line no-misleading-character-class
      .replace(/[\u200B\u200C\u200D\u2060\uFEFF]/g, " ") // Remove ALL zero-width spaces
      .replace(/(?:\\[rnt])+/g, " ") // Remove carriage return, new lines, and tabs
      .replace(/\s+/g, " ") // Normalize excessive spaces
      .trim()
  ); // Trim leading/trailing spaces
};
