<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      class="integration_node_modal"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item
            class="is-required"
            prop="integration_node.data.url"
            label="URL"
          >
            <el-form-item
              :error="hasInvalidVariable(nodeToBind.integration_node.data.url)"
            >
              <div class="form-item-url">
                <el-select
                  v-model="nodeToBind.integration_node.data.http_method"
                  :placeholder="__('Select')"
                  default-first-option
                  class="http_method_dropdown"
                >
                  <el-option
                    v-for="item in httpMethods"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <input-variable-popper
                  class="url-input-field"
                  :is-text-area="false"
                  :placeholder="__('Enter URL')"
                  v-model="nodeToBind.integration_node.data.url"
                />
              </div>
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item prop="integration_node.data.test_url">
        <el-row
          :gutter="25"
          class="test-row-for-url"
          type="flex"
          justify="space-between"
        >
          <el-col class="http-method-col-test-row" :span="3">
            <div>
              {{ nodeToBind.integration_node.data.http_method }}
            </div>
          </el-col>
          <el-col class="test-url-col-test-row" :span="20">
            <el-input
              type="text"
              v-model="nodeToBind.integration_node.data.test_url"
              :placeholder="__('Test URL')"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item style="margin: 50px 0;" :label="__('Config')">
        <el-tabs v-model="activeTab" class="tabs">
          <el-tab-pane :label="__('Params')" name="params">
            <el-form-item prop="query_params">
              <el-scrollbar :native="false">
                <div style="max-height: 40vh">
                  <query-params
                    v-model="queryParams"
                    default-value-property="test_value"
                    default-key-property="test_key"
                    @invalid-variables="
                      updateInvalidVariableMap($event)('query_params')
                    "
                  ></query-params>
                </div>
              </el-scrollbar>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="__('Body')" name="body" class="bodyTab">
            <div>
              <el-radio-group
                v-model="
                  nodeToBind.integration_node.data.post_body_placeholder
                    .body_type
                "
              >
                <el-radio label="none">none</el-radio>
                <el-radio label="form_urlencoded"
                  >x-www-form-urlencoded
                </el-radio>
                <el-radio label="raw">raw</el-radio>
                <el-radio label="multipart">multipart/form-data</el-radio>
              </el-radio-group>

              <el-dropdown
                v-if="
                  nodeToBind.integration_node.data.post_body_placeholder
                    .body_type === 'raw'
                "
                style="padding-left: 30px"
                trigger="click"
                @command="updateRawDataContentType"
              >
                <span
                  style="cursor:pointer; display: inline-flex; align-items: center; color: dodgerblue"
                >
                  <span>{{ rawDataContentType }}</span>
                  <span style="padding-left: 5px;"
                    ><i class="el-icon-arrow-down el-icon--right"></i
                  ></span>
                </span>
                <el-dropdown-menu
                  class="raw-data-content-type-dropdown"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    :command="contentType"
                    :key="contentType.label"
                    v-for="contentType in rawDataContentTypes"
                  >
                    {{ contentType.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-form-item
              v-if="
                nodeToBind.integration_node.data.post_body_placeholder
                  .body_type === 'form_urlencoded'
              "
              key="form_urlencoded"
              prop="form_urlencoded"
            >
              <el-row type="flex">
                <el-col :span="24">
                  <el-scrollbar :native="false">
                    <div style="max-height: 40vh">
                      <query-params
                        key="form_urlencoded"
                        v-model="bodyParams"
                        default-value-property="test_value"
                        default-key-property="test_key"
                        @invalid-variables="
                          updateInvalidVariableMap($event)('form_urlencoded')
                        "
                      ></query-params>
                    </div>
                  </el-scrollbar>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item
              v-if="
                nodeToBind.integration_node.data.post_body_placeholder
                  .body_type === 'raw'
              "
              prop="raw"
            >
              <el-form-item
                key="raw"
                style="margin-bottom: 22px"
                :error="
                  postBodyPlaceHolderError(
                    nodeToBind.integration_node.data.post_body_placeholder
                      .body_raw
                  )
                "
              >
                <el-row type="flex" style="padding-top: 20px">
                  <el-col :span="24">
                    <input-variable-popper
                      prop="body_raw"
                      v-model="rawBodyContent"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form-item>
            <el-form-item
              v-if="
                nodeToBind.integration_node.data.post_body_placeholder
                  .body_type === 'multipart'
              "
              key="multipart"
              prop="multipart"
            >
              <el-row type="flex">
                <el-col :span="24">
                  <el-scrollbar :native="false">
                    <div style="max-height: 40vh">
                      <query-params
                        key="form_data"
                        v-model="formData"
                        default-value-property="test_value"
                        default-key-property="test_key"
                        use-as-multi-part-params
                        @file-uploaded="pushToFilesUploaded"
                        @invalid-variables="
                          updateInvalidVariableMap($event)('multipart')
                        "
                        :task_id="task_id"
                      ></query-params>
                    </div>
                  </el-scrollbar>
                </el-col>
              </el-row>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="__('Headers')" name="headers">
            <el-form-item prop="headers">
              <el-scrollbar :native="false">
                <div style="max-height: 40vh">
                  <query-params
                    v-model="headerParams"
                    default-value-property="test_value"
                    default-key-property="test_key"
                    @invalid-variables="
                      updateInvalidVariableMap($event)('headers')
                    "
                  ></query-params>
                </div>
              </el-scrollbar>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="__('Authentications')" name="authentications">
            <el-form-item>
              <el-row type="flex">
                <el-col :span="6">
                  <el-form-item :label="__('Authentication')">
                    <el-button-group class="auth-button-group">
                      <el-button
                        :loading="loadingTypes"
                        default-first-option
                        v-for="type in auth_types"
                        v-show="type.show"
                        :key="type.value"
                        :value="type.value"
                        :label="type.label"
                        @click="selectAuthType(type.value)"
                        :class="{ active: type.value === selectedAuthType }"
                      >
                        {{ __(type.label) }}
                      </el-button>
                    </el-button-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :span="18"
                  style="margin-top: 10px"
                  v-if="
                    nodeToBind.integration_node.data.authentications_placeholder
                      .auth_type !== 'none'
                  "
                >
                  <el-row type="flex">
                    <el-button-group class="auth-button-group">
                      <el-button
                        v-if="isBasicAuthProfile"
                        @click="setUseAuthProfile(false)"
                        :class="{ active: !useAuthProfile }"
                        >{{ __("Username/Password") }}
                      </el-button>
                      <el-button
                        v-if="isAuthProfileSelected"
                        @click="useAuthenticationProfile"
                        :class="{
                          active: useAuthProfile && !isVariableAuthProfileInUse
                        }"
                        >{{ __("Authentication Profile") }}
                      </el-button>
                      <el-button
                        v-if="isAuthProfileSelected"
                        @click="useAuthenticationVariable"
                        :class="{
                          active: useAuthProfile && isVariableAuthProfileInUse
                        }"
                        >{{ __("Variable") }}
                      </el-button>
                    </el-button-group>
                  </el-row>
                  <el-row style="margin-top: 10px">
                    <template
                      v-if="useAuthProfile && !isVariableAuthProfileInUse"
                    >
                      <span class="inner-title">{{
                        __("Authentication Profile")
                      }}</span>
                      <el-form-item
                        :v-loading="authProfilesLoading"
                        prop="integration_node.data.authentications_placeholder.selected_AuthProfile"
                      >
                        <el-select
                          v-model="
                            nodeToBind.integration_node.data
                              .authentications_placeholder.selected_AuthProfile
                          "
                          :placeholder="__('Select Authentication Profile')"
                          default-first-option
                          filterable
                          style="width: 80%;"
                          @change="selectAuthProfile"
                          :loading="authProfilesLoading"
                          loading-text="Loading Authentications..."
                        >
                          <el-option
                            ref="authProfileOption"
                            v-for="authProfile in authProfiles"
                            :key="authProfile.auth_profile_id"
                            :value="authProfile.auth_profile_id"
                            :label="authProfile.auth_profile_name"
                            :disabled="
                              disableAuthProfileForErrorStatus(authProfile)
                            "
                          >
                            <div
                              style="display: flex; justify-content:space-between;"
                              v-if="
                                authProfile.auth_profile_type.includes('OAuth2')
                              "
                            >
                              <span>{{ authProfile.auth_profile_name }}</span>
                              <span
                                v-if="
                                  disableAuthProfileForErrorStatus(authProfile)
                                "
                                >{{ __("status") }}:
                                <span style="color: darkred">{{
                                  __("error")
                                }}</span></span
                              >
                              <span v-else
                                >{{ __("status") }}:
                                <span style="color: green">{{
                                  __("ok")
                                }}</span></span
                              >
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                    <template
                      v-if="useAuthProfile && isVariableAuthProfileInUse"
                    >
                      <el-row type="flex">
                        <el-col :span="12">
                          <span class="inner-title">{{ __("Variable") }}</span>
                          <el-form-item
                            :v-loading="authProfilesLoading"
                            prop="integration_node.data.authentications_placeholder.selected_VariableAuthProfile"
                          >
                            <el-select
                              v-model="
                                nodeToBind.integration_node.data
                                  .authentications_placeholder
                                  .selected_VariableAuthProfile
                              "
                              :placeholder="__('Select Variable')"
                              default-first-option
                              filterable
                              style="width: 100%;"
                              @change="selectVariableAuthProfile"
                              :loading="authProfilesLoading"
                              loading-text="Loading Variables for Authentication..."
                            >
                              <el-option
                                v-for="variable in singleValuedVariables"
                                :key="variable.variable_id"
                                :value="variable.variable_id"
                                :label="variable.variable_name"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12" style="padding-left: 10px">
                          <span class="inner-title">{{
                            __("Test Value")
                          }}</span>
                          <el-form-item
                            prop="integration_node.data.authentications_placeholder.testAuthProfileName"
                          >
                            <el-input
                              :placeholder="__('Enter test value')"
                              style="width: 100%;"
                              type="text"
                              v-model="
                                nodeToBind.integration_node.data
                                  .authentications_placeholder
                                  .testAuthProfileName
                              "
                            ></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </template>
                  </el-row>

                  <template
                    v-if="
                      nodeToBind.integration_node.data
                        .authentications_placeholder.auth_type === 'cert' ||
                        !useAuthProfile
                    "
                  >
                    <el-row type="flex" style="margin-top: 10px">
                      <el-col :span="12">
                        <span class="inner-title">{{
                          isBasicAuthProfile && !isUsingAuthProfile
                            ? __("Username")
                            : __("Username (optional)")
                        }}</span>
                        <el-form-item>
                          <el-row :span="12">
                            <input-variable-popper
                              style="width: 100%"
                              :placeholder="__('Enter Username')"
                              autocomplete="off"
                              v-model="
                                nodeToBind.integration_node.data
                                  .authentications_placeholder.username
                              "
                              :is-text-area="false"
                            ></input-variable-popper>
                          </el-row>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" style="margin-left: 10px">
                        <span class="inner-title">{{
                          isBasicAuthProfile && !isUsingAuthProfile
                            ? __("Password")
                            : __("Password (optional)")
                        }}</span>
                        <el-form-item>
                          <el-row :span="12">
                            <input-variable-popper
                              style="width: 100%"
                              :placeholder="__('Enter Password')"
                              v-model="
                                nodeToBind.integration_node.data
                                  .authentications_placeholder.password
                              "
                              :is-text-area="false"
                            ></input-variable-popper>
                          </el-row>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                </el-col>
                <el-col
                  v-if="
                    nodeToBind.integration_node.data.authentications_placeholder
                      .auth_type === 'none'
                  "
                  :span="18"
                >
                  <el-row type="flex">
                    <el-col :span="6"></el-col>
                    <el-col :span="24" style="padding-top: 45px"
                      >{{ __("This request does not use any authorization") }}
                    </el-col>
                    <el-col :span="6"></el-col>
                  </el-row>
                </el-col>
              </el-row>

              <el-form-item :label="__('SSL')" style="padding-top: 24px">
                <el-row class="ssl-cert ssl__row">
                  <el-col :span="6" style="padding-left: 10px">
                    <span class="inner-title">{{ __("SSL Certificate") }}</span>
                    <el-form-item>
                      <el-row :span="6">
                        <file-uploader-minimal
                          :folder="`tasks/${task_id}/ssl/certs`"
                          :file-url="ssl_cert_path"
                          :file-name="ssl_cert_name"
                          encrypt-contents
                          :allow-types="['pem', 'crt', 'p12']"
                          @on-success="handleCertFileUploadSuccess"
                          @on-delete="handleCertFileRemove"
                        />
                      </el-row>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10" style="margin-left: 10px">
                    <span class="inner-title">{{
                      __("SSL Certificate Password")
                    }}</span>
                    <el-form-item>
                      <el-row :span="6">
                        <el-input
                          key="integration-node-ssl-cert-password"
                          :placeholder="__('Enter Password')"
                          type="password"
                          :show-password="true"
                          autocomplete="new-password"
                          v-model="
                            nodeToBind.integration_node.data.ssl.ssl_cert_pwd
                          "
                        ></el-input>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="ssl-cert ssl__row">
                  <el-col :span="6" style="margin-left: 10px">
                    <span class="inner-title">{{ __("SSL Key") }}</span>
                    <el-form-item>
                      <el-row :span="6">
                        <file-uploader-minimal
                          :folder="`tasks/${task_id}/ssl/keys`"
                          :file-url="ssl_key_path"
                          :file-name="ssl_key_name"
                          encrypt-contents
                          :allow-types="['pem', 'p12', 'key']"
                          @on-success="handleKeyFileUploadSuccess"
                          @on-delete="handleKeyFileRemove"
                        />
                      </el-row>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <span class="inner-title">{{
                      __("SSL Key Password")
                    }}</span>
                    <el-form-item>
                      <el-row :span="6">
                        <el-input
                          key="integration-node-ssl-key-password"
                          type="password"
                          :placeholder="__('Enter Password')"
                          :show-password="true"
                          autocomplete="new-password"
                          v-model="
                            nodeToBind.integration_node.data.ssl.ssl_key_pwd
                          "
                        ></el-input>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="__('Settings')" name="settings">
            <el-form-item
              prop="integration_node.data.fetch_timeout"
              :label="__('Fetch Timeout')"
            >
              <el-input-number
                v-model="nodeToBind.integration_node.data.fetch_timeout"
                :min="3"
                :max="180"
              ></el-input-number>
              <span style="margin-left: 10px;">{{ __("seconds") }}</span>
            </el-form-item>

            <el-form-item
              prop="integration_node.data.music_on_hold"
              :label="__('Music On Hold')"
              style="margin-top: 20px;"
            >
              <wav-file-uploader
                :folder="`tasks/${task_id}/integration_node/moh`"
                :file-url="mohUrl"
                :file-name="moh_name"
                :max-size-in-mb="3"
                @on-success="handleMOHUploadSuccess"
                @on-delete="handleMOHFileRemove"
              />
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item :label="__('Response')" class="response">
        <el-divider></el-divider>
        <test-web-service
          ref="webService"
          :response="httpResponse"
          :get-response-using="requestResponseFromTestURL"
          :test-data="testData"
          response-editable
          json-path-editable
          :show-edit-btn="!testUrl"
          :enable-preview-button="!!testUrl"
          :preview-response-popover-content="
            __('Set Test URL above to activate this button')
          "
          :allow-raw-response="true"
          :show-raw-response="rawResponseSelected"
          :current-raw-response-variable-id="currentRawResponseVariableId"
          :btn-color-scheme="colorScheme"
          @rule-added="addVariableRule"
          @change="updateHttpResponse"
          @select-raw="selectRawResponse"
        >
          <!-- you don't have to include the template if the requirement is assign json path to variable as a rule_value -->
          <!-- it's already taken care by default slot content in test-web-service component -->
          <!-- but, added the below template to show how to provide a different content for the slot-->
          <template
            #rule="{ruleValue, disableSubmit, resetRuleWhen: resetRule, addRule, parts, audioCols, isRawResponse, currentRawResponseVariableId}"
          >
            <!--  @finish event handler is calling 'addRule' method in test-web-service component,
                               which in turn clears the filled json path and trigger the
                               input event which will call the addVariableRule method mentioned in @input handler of
                               test-web-service component -->
            <json-path-to-variable
              :audio-cols="audioCols"
              :parts="parts"
              :disable-submit="disableSubmit"
              :rule-value="ruleValue"
              :reset-rule-when="resetRule"
              :is-raw-response="isRawResponse"
              :current-raw-response-variable-id="currentRawResponseVariableId"
              @finish="addRule"
            />
          </template>
        </test-web-service>
      </el-form-item>
      <el-row
        v-if="!emptyRules && !rawResponseSelected"
        type="flex"
        style="margin-top: 20px"
      >
        <el-col>
          <el-table class="json-paths" fit :data="getRules" style="width: 100%">
            <el-table-column :label="__('JSON Path')" prop="rule_value">
              <template slot-scope="scope">
                {{ prettify(jsonPathValue(scope.row.rule_value, variables)) }}
              </template>
            </el-table-column>
            <el-table-column :label="__('Variable')" prop="variable_name">
              <template slot-scope="scope">
                <variable-dropdown
                  :placeholder="__('Variable Name')"
                  :new-item-message="__('new variable')"
                  :studioVariables="singleValuedAndSecureVariables"
                  :currentSelect="currentSelection(scope.row)"
                  :variableRule="getVariableRule(scope.row)"
                  @updateVariables="handleVariableUpdate"
                  :nodeData="nodeToBind.integration_node"
                  :showNewVariableMessage="false"
                  :key="getVariableRule(scope.row) + scope.row.variable_id"
                />
              </template>
            </el-table-column>

            <el-table-column :label="__('Default Value')" prop="default_value">
              <template slot-scope="scope">
                <input-variable-popper
                  v-model="scope.row.default_value"
                  :is-text-area="false"
                  force-reinitialize
                  :placeholder="__('Default Value')"
                  :include-secure-variables="false"
                ></input-variable-popper>
              </template>
            </el-table-column>
            <el-table-column class-name="test-variable" width="100px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  class="test-variable-btn"
                  @click="evaluateAssignedPath(scope.row.rule_value)"
                  >{{ __("Test") }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column class-name="row-message" width="125px" prop="msg">
              <template slot-scope="scope">
                <span
                  v-if="newVariableDetected(scope.row)"
                  class="row-message"
                  >{{ __("new variable") }}</span
                >
              </template>
            </el-table-column>
            <el-table-column class-name="cell-item-pointer" width="50px">
              <template slot-scope="scope">
                <span @click="removeJsonPath(scope.row)">
                  <i class="el-icon-circle-close"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
/*import {
  integrationNode,
  integrationNodeDisabled,
  integrationNodeHover
} from "@/styles/node-palette.scss";*/
import _ from "lodash";

import QueryParams from "../components/QueryParams";

import { removeFiles, requestResponseFromTestURL } from "@/api/services";
import {
  filterRowsIfSomeKeyValueIsAbsent,
  generateKeyValueFrom
} from "@/utils/collection";

import InputVariablePopper from "../components/InputVariablePopper";
import TestWebService from "../components/TestWebService";
import JsonPathToVariable from "../components/JsonPathToVariable";
import { jsonPathValue } from "@/utils/canvas";

import { NODE_TYPES } from "@/constants/nodes";
import SchemaGenerator from "@/utils/SchemaGenerator";
import { prettifyJsonPath } from "@/utils/transformers";
import FileUploaderMinimal from "@/components/uploaders/types/FileUploaderMinimal";
import path from "path";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader";
import { mapActions, mapState } from "vuex";
import VariableDropdown from "@/views/build/callflow/components/node-type-forms/components/VariableDropdown.vue";
import NodeUsesVariableDropdown from "@/views/build/callflow/components/node-type-forms/NodeUsesVariableDropdown.vue";
import { validateContent } from "@/utils/contentValidator";
import { replaceContent } from "@/utils/ContentReplacer";

export default {
  mixins: [BaseNode, NodeUsesVariableDropdown],
  components: {
    VariableDropdown,
    WavFileUploader,
    FileUploaderMinimal,
    QueryParams,
    TestWebService,
    InputVariablePopper,
    JsonPathToVariable
  },
  data() {
    const validateQueryParams = (rule, value, cb) => {
      if (this.invalidVariableMap["query_params"]) {
        cb(__("Invalid variables found in Params"));
      } else {
        cb();
      }
    };
    const validateFormUrlEncoded = (rule, value, cb) => {
      if (
        this.nodeToBind.integration_node.data.post_body_placeholder
          .body_type === "form_urlencoded" &&
        this.invalidVariableMap["form_urlencoded"]
      ) {
        cb(__("Invalid variables found in Body"));
      } else {
        cb();
      }
    };

    const validateAuthProfileId = (rule, value, cb) => {
      if (
        this.nodeToBind.integration_node.data.authentications_placeholder
          .auth_type !== "none" &&
        this.nodeToBind.integration_node.data.authentications_placeholder
          .useAuthProfile &&
        !this.nodeToBind.integration_node.data.authentications_placeholder
          .useVariableAuthProfile &&
        !this.nodeToBind.integration_node.data.authentications_placeholder
          .selected_AuthProfile
      ) {
        cb(__("Select an Authentication"));
      } else {
        cb();
      }
    };

    const validateVariableId = (rule, value, cb) => {
      if (
        this.nodeToBind.integration_node.data.authentications_placeholder
          .auth_type !== "none" &&
        this.nodeToBind.integration_node.data.authentications_placeholder
          .useAuthProfile &&
        this.nodeToBind.integration_node.data.authentications_placeholder
          .useVariableAuthProfile &&
        !this.nodeToBind.integration_node.data.authentications_placeholder
          .selected_VariableAuthProfile
      ) {
        cb("Select a Variable");
      } else {
        cb();
      }
    };

    const validateMultiPart = (rule, value, cb) => {
      if (
        this.nodeToBind.integration_node.data.post_body_placeholder
          .body_type === "multipart" &&
        this.invalidVariableMap["multipart"]
      ) {
        cb(__("Invalid variables found in Body"));
      } else {
        cb();
      }
    };

    const validateRawBody = (rule, value, cb) => {
      if (
        this.nodeToBind.integration_node.data.post_body_placeholder
          .body_type === "raw" &&
        this.invalidVariableMap["raw"]
      ) {
        cb(__("Invalid variables found in Body"));
      } else if (
        this.nodeToBind.integration_node.data.post_body_placeholder
          .body_type === "raw" &&
        this.invalidRawDataFormat
      ) {
        cb(__(this.rawBodyErrorMessage));
      } else {
        cb();
      }
    };

    const validateHeaders = (rule, value, cb) => {
      if (this.invalidVariableMap["headers"]) {
        cb(__("Invalid variables found in Headers"));
      } else {
        cb();
      }
    };

    const validateUrl = (rule, value, cb) => {
      if (!value) {
        cb(__("URL cannot be empty"));
      } else if (
        this.hasInvalidVariable(this.nodeToBind.integration_node.data.url)
      ) {
        cb(__("Invalid variables found in URL"));
      } else if (value.length > 500) {
        cb(__("URL should be no more than 500 characters"));
      } else {
        cb();
      }
    };
    const validateTestUrl = (rule, value, cb) => {
      if (value && value.length > 500) {
        cb(__("Test URL should be no more than 500 characters"));
      } else {
        cb();
      }
    };

    return {
      filesUploaded: [],
      loadingTypes: true,
      rawBodyErrorMessage: "",
      rules: {
        "integration_node.data.url": { validator: validateUrl },
        "integration_node.data.test_url": {
          validator: validateTestUrl
        },
        query_params: {
          validator: validateQueryParams
        },
        form_urlencoded: {
          validator: validateFormUrlEncoded
        },
        multipart: {
          validator: validateMultiPart
        },
        headers: {
          validator: validateHeaders
        },
        raw: {
          validator: validateRawBody
        },
        "integration_node.data.authentications_placeholder.selected_AuthProfile": {
          validator: validateAuthProfileId
        },
        "integration_node.data.authentications_placeholder.selected_VariableAuthProfile": {
          validator: validateVariableId
        }
      },
      // testUrl: "",
      colorScheme: {
        normal: "var(--theme-color)",
        disabled: "var(--theme-color-light-10p)",
        hover: "var(--theme-hover-color)"
      },
      invalidRawDataFormat: false,
      invalidVariableMap: {
        query_params: false,
        form_urlencoded: false,
        multipart: false,
        headers: false,
        raw: false
      },
      httpMethods: [
        {
          value: "GET",
          label: "GET"
        },
        {
          value: "POST",
          label: "POST"
        },
        {
          value: "PUT",
          label: "PUT"
        },
        {
          value: "DELETE",
          label: "DELETE"
        },
        {
          value: "PATCH",
          label: "PATCH"
        }
      ],
      requestResponseFromTestURL: requestResponseFromTestURL,
      activeTab: "params",
      httpResponse: "",
      variableRulesBckup: [],
      authProfiles: {},
      initialAuthProfileId: null,
      auth_types: [
        {
          value: "none",
          label: __("None")
        },
        {
          value: "basic",
          label: __("Basic/Digest")
        },
        {
          value: "OAuth2",
          label: __("OAuth2")
        },
        {
          value: "cert",
          label: __("Certificate")
        },
        {
          value: "API_Key",
          label: __("API Key")
        }
      ],
      selectedAuthType: "none",
      authenticationsPlaceholderBackup: {},
      postBodyPlaceholderBackup: [],
      jsonPathValue,
      prettify: prettifyJsonPath,
      // existingSSLCertificateFile: "",
      // existingSSLKeyFile: "",
      // existingMOHFile: "",
      mohUrl: "",
      formData: [],
      rawDataContentType: __("Text"),
      rawDataContentTypes: [
        {
          label: __("Text"),
          header: "text/plain"
        },
        {
          label: __("JSON"),
          header: "application/json"
        },
        {
          label: __("XML"),
          header: "application/xml"
        },
        {
          label: __("HTML"),
          header: "text/html"
        },
        {
          label: __("JavaScript"),
          header: "application/javascript"
        }
      ],
      nodeName: "integration_node",
      rawDataContentTypeHeader: "",
      contentTypeHeaderMap: {
        multipart: `multipart/form-data; boundary=<calculated when request is sent>`,
        form_urlencoded: "application/x-www-form-urlencoded"
      },
      rawResponseSelected: false,
      currentRawResponseVariableId: null
    };
  },
  computed: {
    ...mapState("authprofiles", {
      authProfilesLoading: state => state.loading,
      auth_profile_type: state => state.auth_profile_type
    }),

    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    postBodyPlaceHolderError() {
      return bodyRaw => {
        return (
          this.hasInvalidVariable(bodyRaw) || this.hasInvalidRawFormat(bodyRaw)
        );
      };
    },
    rawBodyContent: {
      get() {
        return this.nodeToBind.integration_node.data.post_body_placeholder
          .body_raw;
      },
      set(value) {
        this.nodeToBind.integration_node.data.post_body_placeholder.body_raw = value;
      }
    },
    hasInvalidRawFormat() {
      return value => {
        let validationResult = validateContent(
          value,
          this.rawDataContentTypeHeader
        );

        if (validationResult.valid) {
          this.invalidRawDataFormat = false;
          this.rawBodyErrorMessage = "";
          return "";
        }

        this.rawBodyErrorMessage = validationResult.error;
        this.invalidRawDataFormat = true;
        return validationResult.error;
      };
    },
    contentTypeHeaderRow() {
      return (
        _.find(
          JSON.parse(this.headerParams),
          header =>
            header.key === "Content-Type" || header.test_key === "Content-Type"
        ) || {}
      );
    },

    emptyURL() {
      if (!this.nodeToBind.integration_node.data.url) {
        return __("URL cannot be empty");
      }
      return "";
    },

    bodyType() {
      return _.get(
        this.nodeToBind,
        "integration_node.data.post_body_placeholder.body_type",
        ""
      );
    },

    isBasicAuthProfile() {
      return this.auth_profile_type === "Basic";
    },

    isAuthProfileSelected() {
      return this.auth_profile_type !== "none";
    },

    isUsingAuthProfile() {
      return this.useAuthProfile;
    },

    useAuthProfile: {
      get() {
        return this.nodeToBind.integration_node.data.authentications_placeholder
          .useAuthProfile;
      },
      set(val) {
        this.$set(
          this.nodeToBind.integration_node.data.authentications_placeholder,
          "useAuthProfile",
          val
        );
      }
    },

    isVariableAuthProfileInUse: {
      get() {
        return (
          this.nodeToBind.integration_node.data.authentications_placeholder
            .useVariableAuthProfile || false
        );
      },
      set(val) {
        this.$set(
          this.nodeToBind.integration_node.data.authentications_placeholder,
          "useVariableAuthProfile",
          val
        );
      }
    },

    ssl_cert_path: {
      get() {
        return this.nodeToBind.integration_node.data.ssl.ssl_cert;
      },
      set(path) {
        this.$set(this.nodeToBind.integration_node.data.ssl, "ssl_cert", path);
      }
    },

    ssl_key_path: {
      get() {
        return this.nodeToBind.integration_node.data.ssl.ssl_key;
      },
      set(path) {
        this.$set(this.nodeToBind.integration_node.data.ssl, "ssl_key", path);
      }
    },

    moh: {
      get() {
        return this.nodeToBind.integration_node.data.music_on_hold;
      },
      set(path) {
        this.$set(this.nodeToBind.integration_node.data, "music_on_hold", path);
      }
    },

    ssl_cert_name() {
      return this.ssl_cert_path ? this.getTailName(this.ssl_cert_path) : "";
    },

    ssl_key_name() {
      return this.ssl_key_path ? this.getTailName(this.ssl_key_path) : "";
    },

    moh_name() {
      return this.moh ? this.getTailName(this.moh) : "";
    },

    testUrl() {
      return this.nodeToBind.integration_node.data.test_url;
    },
    testData() {
      const testObjectRule = {
        key: "test_key",
        value: "test_value"
      };

      return {
        url: this.testUrl,
        http_method: this.nodeToBind.integration_node.data.http_method,
        fetch_timeout: this.nodeToBind.integration_node.data.fetch_timeout,
        ssl: this.nodeToBind.integration_node.data.ssl,
        task_id: +this.task_id,
        ac_id: this.selectedAccountId,
        node_type: this.nodeToBind.node_type,
        headers: this.stringifyJsonArray(
          generateKeyValueFrom(
            filterRowsIfSomeKeyValueIsAbsent(
              this.nodeToBind.integration_node.data.headers,
              "test_key"
            ),
            testObjectRule
          )
        ),
        parameters: this.stringifyJsonArray(
          generateKeyValueFrom(
            filterRowsIfSomeKeyValueIsAbsent(
              this.nodeToBind.integration_node.data.parameters,
              "test_key"
            ),
            testObjectRule
          )
        ),
        authentications: JSON.stringify(
          this.nodeToBind.integration_node.data.authentications_placeholder
        ),
        post_raw_data: this.formatPostParameters(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          testObjectRule
        )
      };
    },
    emptyRules() {
      return _.isEmpty(this.getRules);
    },
    queryParams: {
      get: function() {
        return this.stringifyJsonArray(
          _.get(this.nodeToBind, "integration_node.data.parameters", [])
        );
      },
      set: function(val) {
        this.$set(this.nodeToBind.integration_node.data, "parameters", val);
      }
    },
    headerParams: {
      get: function() {
        return this.stringifyJsonArray(
          _.get(this.nodeToBind, "integration_node.data.headers", [])
        );
      },
      set: function(val) {
        this.$set(this.nodeToBind.integration_node.data, "headers", val);
      }
    },
    bodyParams: {
      get: function() {
        return this.stringifyJsonArray(
          _.get(
            this.nodeToBind,
            "integration_node.data.post_body_placeholder.body_form_urlencoded",
            []
          )
        );
      },

      set: function(val) {
        this.$set(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          "body_form_urlencoded",
          val
        );
      }
    },
    variableRules() {
      return [
        ..._.get(
          this.nodeToBind,
          "integration_node.data.variable_rules.data",
          []
        ),
        ..._.get(
          this.nodeToBind,
          "integration_node.data.interaction_variable_rules.data",
          []
        )
      ];
    },
    studioVariableRules() {
      return _.get(
        this.nodeToBind,
        "integration_node.data.variable_rules.data",
        []
      );
    },
    interactionVariableRules() {
      return _.get(
        this.nodeToBind,
        "integration_node.data.interaction_variable_rules.data",
        []
      );
    },
    getRules() {
      return _.filter(
        this.isNodeSubmit ? this.variableRulesBckup : this.variableRules,
        rule => !rule.array_variable
      );
    }

    // unUsedVariables() {
    //   return _.filter(
    //     this.variables,
    //     variable =>
    //       !_.some(
    //         this.variableRules,
    //         rule => rule.variable_id === variable.variable_id
    //       )
    //   );
    // },
    // jsonPathConfigured() {
    //   return path => {
    //     return _.some(
    //       this.variableRules,
    //       rule => rule.rule_value === path.trim()
    //     );
    //   };
    // }
  },

  created() {
    this.initializeIntegrationNode();
  },

  methods: {
    evaluateAssignedPath(path) {
      this.$set(
        this.$refs.webService.$data,
        "showData",
        /^root\[(['"])ws_response_data(['"])]/.test(path)
      );
      this.$refs.webService.evaluateJsonPath(path);
    },
    ...mapActions("authprofiles", {
      setAuthProfileType: "setAuthProfileType",
      getAuthProfiles: "getAuthProfiles"
    }),

    async showTypes() {
      for (const [index, item] of this.auth_types.entries()) {
        this.auth_types[index]["show"] = item.ld_key
          ? await this.showFeature(item.ld_key)
          : true;
      }
      this.loadingTypes = false;
    },

    pushToFilesUploaded(path) {
      !_.includes(this.filesUploaded, path) && this.filesUploaded.push(path);
    },

    disableAuthProfileForErrorStatus(auth_profile) {
      return (
        ["OAuth2_CC", "OAuth2_JWT"].includes(auth_profile.auth_profile_type) &&
        auth_profile.auth_profile_token &&
        auth_profile.auth_profile_token.data.status !== "ok"
      );
    },

    deduceContentType(row) {
      return row.test_value || row.value;
    },

    updateContentTypeHeader(type, header) {
      let headers = JSON.parse(this.headerParams);
      // can be commented if we don't want to show a toast while we modify the Content-Type header
      let originalHeader = _.cloneDeep(this.contentTypeHeaderRow);

      if (!header) {
        this.headerParams = this.stringifyJsonArray(
          _.reject(
            headers,
            header =>
              header.key === "Content-Type" ||
              header.test_key === "Content-Type"
          )
        );
      } else {
        let contentTypeHeader = {
          ...this.contentTypeHeaderRow,
          ...{
            key: "Content-Type",
            test_key: "Content-Type",
            value: header,
            test_value: header
          }
        };
        this.headerParams = this.stringifyJsonArray(
          _.concat(
            contentTypeHeader,
            _.reject(
              headers,
              header =>
                header.key === "Content-Type" ||
                header.test_key === "Content-Type"
            )
          )
        );
      }

      // can be commented if we dont want to show a toast while we modify the Content-Type header
      this.$nextTick(() => {
        if (!_.isEqual(this.contentTypeHeaderRow, originalHeader)) {
          this.$message({
            // eslint-disable-next-line
            message: __("Content-Type request header have been modified, please review"),
            type: "info"
          });
        }
      });
    },

    updateRawDataContentType(contentType) {
      this.rawDataContentType = contentType.label;
      this.rawDataContentTypeHeader = contentType.header;
      this.updateContentTypeHeader("raw", this.rawDataContentTypeHeader);
    },

    updateInvalidVariableMap(val) {
      return key => {
        let value = val;
        if (
          key === "form_urlencoded" &&
          this.nodeToBind.integration_node.data.post_body_placeholder
            .body_type !== "form_urlencoded"
        ) {
          value = false;
        }
        if (
          key === "multipart" &&
          this.nodeToBind.integration_node.data.post_body_placeholder
            .body_type !== "multipart"
        ) {
          value = false;
        }

        this.invalidVariableMap[key] = value;
      };
    },

    handleCertFileUploadSuccess({ path }) {
      this.ssl_cert_path = path;
      this.pushToFilesUploaded(path);
    },

    handleKeyFileUploadSuccess({ path }) {
      this.ssl_key_path = path;
      this.pushToFilesUploaded(path);
    },

    handleMOHUploadSuccess({ path, url }) {
      this.moh = path;
      this.mohUrl = url;
      this.pushToFilesUploaded(path);
    },

    handleCertFileRemove() {
      this.ssl_cert_path = "";
    },

    handleKeyFileRemove() {
      this.ssl_key_path = "";
    },

    handleMOHFileRemove() {
      this.moh = "";
      this.mohUrl = "";
    },

    useAuthenticationProfile() {
      this.useAuthProfile = true;
      this.isVariableAuthProfileInUse = false;
    },

    initializeAuthenticationScheme() {
      this.useAuthProfile = this.useAuthProfile || true;
      this.isVariableAuthProfileInUse =
        this.isVariableAuthProfileInUse || false;
    },

    useAuthenticationVariable() {
      this.useAuthProfile = true;
      this.isVariableAuthProfileInUse = true;
    },

    setUseAuthProfile(value) {
      this.useAuthProfile = value;
    },

    getName(p) {
      return path.basename(p);
    },

    getTailName(p) {
      return _(_.split(this.getName(p), "_"))
        .tail()
        .value()
        .join("_");
    },

    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
          this.$set(row, "msg", option.msg);
        });
      };
    },
    arrayRule() {
      let arrayRule = _.find(
        this.variableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true
        };
        this.nodeToBind.integration_node.data.variable_rules.data.push(
          arrayRule
        );
      }
      return arrayRule;
    },

    updateHttpResponse(httpResponse) {
      this.httpResponse = httpResponse;
    },

    selectAuthProfile(selected_AuthProfile) {
      if (selected_AuthProfile) {
        this.nodeToBind.integration_node.data.authentications_placeholder.selected_AuthProfile = selected_AuthProfile;
      }
    },
    selectVariableAuthProfile(selected_VariableAuthProfile) {
      if (selected_VariableAuthProfile) {
        this.nodeToBind.integration_node.data.authentications_placeholder.selected_VariableAuthProfile = selected_VariableAuthProfile;
      }
    },

    selectAuthType(authType) {
      this.selectedAuthType = authType;
      this.nodeToBind.integration_node.data.authentications_placeholder.auth_type = authType;
      switch (authType) {
        case "basic":
          this.initAuthProfile("Basic");
          this.useAuthProfile = this.useAuthProfile || false; // Using Username/Password as default
          this.isVariableAuthProfileInUse =
            this.isVariableAuthProfileInUse || false;
          break;
        case "OAuth2":
        case "API_Key":
          this.initAuthProfile(authType);
          this.initializeAuthenticationScheme();
          break;
        case "cert":
          this.initAuthProfile("Certificate");
          this.initializeAuthenticationScheme();
          break;
        default:
          this.useAuthProfile = false;
      }
    },

    initAuthProfile(authType = "Basic") {
      if (this.auth_profile_type !== authType || _.isEmpty(this.authProfiles)) {
        this.setAuthProfileType(authType);
        this.getAuthProfiles({ notShowLoader: false, fetch_all: 1 })
          .then(res => {
            this.authProfiles = res.data;
            if (
              _.isEmpty(
                _.filter(this.authProfiles, {
                  auth_profile_id: this.initialAuthProfileId
                })
              )
            ) {
              this.nodeToBind.integration_node.data.authentications_placeholder.selected_AuthProfile = null;
            } else {
              this.nodeToBind.integration_node.data.authentications_placeholder.selected_AuthProfile = this.initialAuthProfileId;
            }
          })
          .catch(() => {});
      }
    },
    initializeAuthProfile() {
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "auth_type",
        "none"
      );
      this.selectedAuthType = "none";
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "useAuthProfile",
        false
      );
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "selected_AuthProfile",
        null
      );
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "selected_VariableAuthProfile",
        null
      );
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "useVariableAuthProfile",
        false
      );
      this.$set(
        this.nodeToBind.integration_node.data.authentications_placeholder,
        "testAuthProfileName",
        ""
      );
    },

    initializeIntegrationNode() {
      if (
        !this.nodeToBind.node_id ||
        _.isEmpty(this.nodeToBind.integration_node)
      ) {
        this.$set(this.nodeToBind, "integration_node", {});
        this.$set(this.nodeToBind.integration_node, "data", {});

        this.$set(this.nodeToBind.integration_node.data, "url", "");
        this.$set(this.nodeToBind.integration_node.data, "http_method", "POST");

        this.$set(this.nodeToBind.integration_node.data, "ssl", {
          ssl_cert: "",
          ssl_cert_pwd: "",
          ssl_key: "",
          ssl_key_pwd: ""
        });

        this.$set(this.nodeToBind.integration_node.data, "music_on_hold", "");

        this.$set(
          this.nodeToBind.integration_node.data,
          "is_raw_response",
          false
        );

        this.$set(
          this.nodeToBind.integration_node.data,
          "parameters",
          this.queryParams
        );

        this.$set(
          this.nodeToBind.integration_node.data,
          "headers",
          this.headerParams
        );

        this.$set(this.nodeToBind.integration_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.integration_node.data.variable_rules,
          "data",
          []
        );
        this.$set(
          this.nodeToBind.integration_node.data,
          "interaction_variable_rules",
          {}
        );
        this.$set(
          this.nodeToBind.integration_node.data.interaction_variable_rules,
          "data",
          []
        );

        this.$set(this.nodeToBind.integration_node.data, "fetch_timeout", 30);

        this.$set(
          this.nodeToBind,
          "node_type",
          NODE_TYPES.INTEGRATION.NODE_TYPE
        );

        //initialize for the auth tab
        this.$set(
          this.nodeToBind.integration_node.data,
          "authentications_placeholder",
          {}
        );
        this.initializeAuthProfile();
        this.$set(
          this.nodeToBind.integration_node.data.authentications_placeholder,
          "username",
          ""
        );
        this.$set(
          this.nodeToBind.integration_node.data.authentications_placeholder,
          "password",
          ""
        );

        //initialize for the body tab
        this.$set(
          this.nodeToBind.integration_node.data,
          "post_body_placeholder",
          {}
        );
        this.$set(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          "body_type",
          "none"
        );
        this.$set(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          "body_form_urlencoded",
          []
        );
        this.$set(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          "form_data",
          []
        );
        this.$set(
          this.nodeToBind.integration_node.data.post_body_placeholder,
          "body_raw",
          ""
        );
      } else {
        // this.existingSSLCertificateFile = this.ssl_cert_path;
        // this.existingSSLKeyFile = this.ssl_key_path;
        // this.existingMOHFile = this.moh;
        this.moh = this.nodeToBind.integration_node.data.music_on_hold || "";
        this.mohUrl = this.nodeToBind.integration_node.data.music_on_hold_url;

        try {
          this.formData = _.get(
            JSON.parse(this.nodeToBind.integration_node.data.post_raw_data),
            "form_data",
            []
          );
        } catch (error) {
          this.errorMessage = error.message;
        }

        this.rawResponseSelected = !!this.nodeToBind.integration_node.data
          .is_raw_response;
        // this.ssl_cert_path && this.filesUploaded.push(this.ssl_cert_path);
        // this.ssl_key_path && this.filesUploaded.push(this.ssl_key_path);
        if (this.rawResponseSelected) {
          const rawResponse = this.nodeToBind.integration_node.data.variable_rules.data.find(
            item => item.rule_value === "raw_response"
          );
          if (rawResponse) {
            this.currentRawResponseVariableId = rawResponse.variable_id;
          }
        }
        if (_.isEmpty(this.nodeToBind.integration_node.data.variable_rules)) {
          this.$set(
            this.nodeToBind.integration_node.data,
            "variable_rules",
            {}
          );
          this.$set(
            this.nodeToBind.integration_node.data.variable_rules,
            "data",
            []
          );
        }

        if (_.isEmpty(this.nodeToBind.integration_node.data.ssl)) {
          this.$set(this.nodeToBind.integration_node.data, "ssl", {
            ssl_cert: "",
            ssl_cert_pwd: "",
            ssl_key: "",
            ssl_key_pwd: ""
          });
        }

        //initialize auth tab with auth placeholder
        if (this.nodeToBind.integration_node.data.authentications === "") {
          this.$set(
            this.nodeToBind.integration_node.data,
            "authentications_placeholder",
            {}
          );
          this.initializeAuthProfile();
          this.$set(
            this.nodeToBind.integration_node.data.authentications_placeholder,
            "username",
            ""
          );
          this.$set(
            this.nodeToBind.integration_node.data.authentications_placeholder,
            "password",
            ""
          );
        } else {
          this.$set(
            this.nodeToBind.integration_node.data,
            "authentications_placeholder",
            JSON.parse(this.nodeToBind.integration_node.data.authentications)
          );
          if (
            _.isEmpty(
              this.nodeToBind.integration_node.data.authentications_placeholder
                .auth_type
            )
          ) {
            this.initializeAuthProfile();
          } else {
            this.initialAuthProfileId = this.nodeToBind.integration_node.data.authentications_placeholder.selected_AuthProfile;
            this.selectAuthType(
              this.nodeToBind.integration_node.data.authentications_placeholder
                .auth_type
            );
          }
        }

        //initialize body tab with post_body_placeholder
        if (this.nodeToBind.integration_node.data.post_raw_data === "") {
          this.$set(
            this.nodeToBind.integration_node.data,
            "post_body_placeholder",
            {}
          );
          this.$set(
            this.nodeToBind.integration_node.data.post_body_placeholder,
            "body_type",
            "none"
          );
          this.$set(
            this.nodeToBind.integration_node.data.post_body_placeholder,
            "body_form_urlencoded",
            []
          );
          this.$set(
            this.nodeToBind.integration_node.data.post_body_placeholder,
            "form_data",
            []
          );
          this.$set(
            this.nodeToBind.integration_node.data.post_body_placeholder,
            "body_raw",
            ""
          );
        } else {
          let raw_data = JSON.parse(
            this.nodeToBind.integration_node.data.post_raw_data
          );

          // for backward compatibility
          if (_.isObject(raw_data.body_raw)) {
            raw_data.body_raw = JSON.stringify(raw_data.body_raw);
          }

          if (raw_data.body_type === "raw") {
            if (!_.isEmpty(this.contentTypeHeaderRow)) {
              this.rawDataContentTypeHeader = this.deduceContentType(
                this.contentTypeHeaderRow
              );
              this.rawDataContentType = _.get(
                _.find(
                  this.rawDataContentTypes,
                  item => item.header === this.rawDataContentTypeHeader
                ) || {},
                "label",
                "Text"
              );
            }
          }
          this.$set(
            this.nodeToBind.integration_node.data,
            "post_body_placeholder",
            raw_data
          );
        }
      }
      this.nodeToBind.integration_node.data.variable_rules.data = _.map(
        this.studioVariableRules,
        rule => ({
          ...rule,
          array_variable: this.isVariableOfTypeArray(rule.variable_id)
        })
      );

      if (this.arrayRule().default_value) {
        this.httpResponse = JSON.parse(this.arrayRule().default_value);
      }
    },

    cleanPostParameters(parameters) {
      let cleanedBody = filterRowsIfSomeKeyValueIsAbsent(
        parameters.body_form_urlencoded,
        "key"
      );

      let cleanedFormData = filterRowsIfSomeKeyValueIsAbsent(
        this.formData,
        "key"
      );

      let body_raw = parameters.body_raw;

      return {
        ...parameters,
        body_raw,
        body_form_urlencoded: cleanedBody,
        form_data: cleanedFormData
      };
    },

    formatPostParameters(parameters, ruleObject) {
      const multiPartTestObjectRule = {
        type: "type",
        key: "test_key",
        value: "test_value"
      };

      let filteredParameters = generateKeyValueFrom(
        filterRowsIfSomeKeyValueIsAbsent(
          parameters.body_form_urlencoded,
          "test_key"
        ),
        ruleObject
      );

      let filteredFormData = generateKeyValueFrom(
        filterRowsIfSomeKeyValueIsAbsent(this.formData, "test_key"),
        multiPartTestObjectRule
      );

      let body_raw = parameters.body_raw;

      return JSON.stringify({
        ...parameters,
        body_raw,
        body_form_urlencoded: filteredParameters,
        form_data: filteredFormData
      });
    },

    reInitializeVariableRules() {
      return _.map(this.studioVariableRules, rule => ({
        variable_type: rule.variable_type,
        rule_value: rule.rule_value,
        variable_name: rule.variable_name,
        variable_id: rule.variable_id,
        default_value: rule.default_value || ""
      }));
    },

    isVariableOfTypeArray(id) {
      let variable = _.find(
        this.variables,
        variable => variable.variable_id === id
      );
      if (!variable) {
        return false;
      }
      return variable.variable_type === "array";
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      this.variableRulesBckup = _.cloneDeep(this.variableRules);

      try {
        nodeToCleanUp.integration_node.data.variable_rules.data = this.reInitializeVariableRules();
        nodeToCleanUp.integration_node.data.post_body_placeholder.body_raw = replaceContent(
          nodeToCleanUp.integration_node.data.post_body_placeholder.body_raw
        );

        // cleanup key value pairs
        const keyValuePairPaths = ["data.parameters", "data.headers"];

        _.map(keyValuePairPaths, keyValuePairPath => {
          let keyValuePairs = JSON.parse(
            _.get(nodeToCleanUp.integration_node, keyValuePairPath, "[]")
          );

          keyValuePairs = JSON.stringify(
            filterRowsIfSomeKeyValueIsAbsent(keyValuePairs, "key")
          );
          _.set(
            nodeToCleanUp.integration_node,
            keyValuePairPath,
            keyValuePairs
          );
        });

        if (
          nodeToCleanUp.integration_node.data.authentications_placeholder
            .useAuthProfile &&
          nodeToCleanUp.integration_node.data.authentications_placeholder
            .auth_type !== "cert"
        ) {
          nodeToCleanUp.integration_node.data.authentications_placeholder.username =
            "";
          nodeToCleanUp.integration_node.data.authentications_placeholder.password =
            "";
        }

        nodeToCleanUp.integration_node.data.authentications = JSON.stringify(
          nodeToCleanUp.integration_node.data.authentications_placeholder
        );

        let temp = _.cloneDeep(
          nodeToCleanUp.integration_node.data.post_body_placeholder
        );
        nodeToCleanUp.integration_node.data.post_body_placeholder = this.cleanPostParameters(
          nodeToCleanUp.integration_node.data.post_body_placeholder
        );

        nodeToCleanUp.integration_node.data.post_raw_data = JSON.stringify(
          nodeToCleanUp.integration_node.data.post_body_placeholder
        );
        // removing placeholder properties
        this.authenticationsPlaceholderBackup = _.cloneDeep(
          nodeToCleanUp.integration_node.data.authentications_placeholder
        );
        this.postBodyPlaceholderBackup = temp;

        delete nodeToCleanUp.integration_node.data.authentications_placeholder;
        delete nodeToCleanUp.integration_node.data.post_body_placeholder;

        return _.cloneDeep(nodeToCleanUp);
      } catch (err) {
        this.$notify({
          type: "error",
          message: err.message,
          title: __("Error")
        });
        this.toggleNodeSubmit(false);
        return null;
      }
    },

    removeUnnecessaryUploadedFiles(action = "submit") {
      let filesToRemove = this.filesUploaded;
      if (action === "submit") {
        // removes all the uploaded files which are not the currently set files
        // find files used in form_data of request

        let multiPartFilesUsed = _(this.formData)
          .filter(item => item.type === "file" && item.value)
          .map("value")
          .value();

        let filesInUse = [
          ...multiPartFilesUsed,
          this.ssl_cert_path,
          this.ssl_key_path,
          this.moh
        ];

        filesToRemove = _.reject(filesToRemove, file =>
          filesInUse.includes(file)
        );
      } else {
        // removes all uploaded files
        filesToRemove = this.filesUploaded;
      }

      removeFiles(filesToRemove);
      this.filesUploaded = [];
    },

    cleanUpWhenUserCancels() {
      return new Promise(resolve => {
        // remove all the newly uploaded files
        this.removeUnnecessaryUploadedFiles("cancel");
        resolve();
      });
    },

    cleanUpNode() {
      const nodeAfterCleanup = this.cleanUpNodeToPrepareForSubmit();

      // remove files that were uploaded but not going to be used
      this.removeUnnecessaryUploadedFiles("submit");

      // if there was an existing file uploaded and now its not the currently uploaded file

      /** CANNOT DO THIS BECAUSE, IF THE SAME FILE IS REFERENCED BY OTHER NODES IT WOULD BREAK OTHER NODE, SO COMMENTING THIS OUT **/

      // let existingFilesToRemove = [];
      // if (
      //   this.existingSSLKeyFile &&
      //   this.existingSSLKeyFile !== this.ssl_key_path
      // ) {
      //   existingFilesToRemove.push(this.existingSSLKeyFile);
      // }
      //
      // if (
      //   this.existingSSLCertificateFile &&
      //   this.existingSSLCertificateFile !== this.ssl_cert_path
      // ) {
      //   existingFilesToRemove.push(this.existingSSLCertificateFile);
      // }
      //
      // if (this.existingMOHFile && this.existingMOHFile !== this.moh) {
      //   existingFilesToRemove.push(this.existingMOHFile);
      // }
      //
      // removeFiles(existingFilesToRemove);
      // existingFilesToRemove = [];

      if (!_.isEmpty(nodeAfterCleanup)) {
        nodeAfterCleanup.integration_node.data.authentications_placeholder = this.authenticationsPlaceholderBackup;
        nodeAfterCleanup.integration_node.data.post_body_placeholder = this.postBodyPlaceholderBackup;
        nodeAfterCleanup.integration_node.data.variable_rules.data = filterRowsIfSomeKeyValueIsAbsent(
          this.studioVariableRules,
          "rule_value,variable_name"
        );
        nodeAfterCleanup.integration_node.data.is_raw_response = nodeAfterCleanup.integration_node.data.variable_rules.data.some(
          item => item.rule_value === "raw_response"
        );
        // only submit the raw_response variable rule when is_raw_response flag is true
        if (nodeAfterCleanup.integration_node.data.is_raw_response) {
          nodeAfterCleanup.integration_node.data.variable_rules.data = nodeAfterCleanup.integration_node.data.variable_rules.data.filter(
            (item, index) => index === 0 || item.rule_value === "raw_response"
          );
        }
        this.nodeToBind = nodeAfterCleanup;
        this.createOrEditNode();
      }
    },
    selectRawResponse(val) {
      this.rawResponseSelected = val;
      this.nodeToBind.integration_node.data.is_raw_response = val;
      if (!val) {
        this.nodeToBind.integration_node.data.variable_rules.data = this.nodeToBind.integration_node.data.variable_rules.data.filter(
          item => item.rule_value !== "raw_response"
        );
      }
    }
  },
  watch: {
    variableRules: {
      deep: true,
      handler(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
      }
    },
    clickedNode: {
      handler: "initializeIntegrationNode"
    },
    httpResponse: {
      deep: true,
      handler() {
        let rule = this.arrayRule();
        let sg = new SchemaGenerator(this.httpResponse);

        // The schema generated by the original response
        // and schema generated by the sample json after parsing the original response should be identical
        rule.default_value = JSON.stringify(sg.sampleJSON);

        // quick check to see if the above mentioned comment is right by checking the sample json generated by
        // identical schema are same (the difference in schema would be some constraints such as minLength of property
        // or something else, which we are not very concerned about)
      }
    },
    bodyRaw: {
      immediate: true,
      handler(val) {
        if (
          val &&
          _.get(
            this.nodeToBind,
            "integration_node.data.post_body_placeholder.body_type"
          ) === "raw"
        ) {
          this.invalidVariableMap["raw"] = !_.isEmpty(
            this.invalidVariableIdsPresentInAValue(val)
          );
        } else {
          this.invalidVariableMap["raw"] = false;
        }
      }
    },
    bodyType: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          if (val === "none") {
            this.updateContentTypeHeader("none");
          } else if (val === "raw") {
            this.updateContentTypeHeader(
              val,
              this.rawDataContentTypeHeader || "text/plain"
            );
          } else {
            this.updateContentTypeHeader(val, this.contentTypeHeaderMap[val]);
          }
        });
      }
    },
    formData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          _.has(
            this.nodeToBind,
            "integration_node.data.post_body_placeholder.form_data"
          )
        ) {
          this.nodeToBind.integration_node.data.post_body_placeholder.form_data = val;
        }
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.loadingTypes = false;
        } else {
          await this.showTypes();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.integration_node_modal {
  ::v-deep .upload-btn {
    //margin-left: 20px;
    background-color: var(--theme-color) !important;
    border: none;
    color: white;

    &:hover {
      background-color: var(--theme-hover-color) !important;
      border-color: var(--theme-hover-color) !important;
    }

    &.is-disabled {
      background-color: var(--theme-color-light-10p) !important;
      border-color: var(--theme-color-light-10p) !important;
      cursor: not-allowed;
    }
  }
}

.form-item-url {
  display: flex;

  .http_method_dropdown {
    width: 100px;
    color: black;

    ::v-deep .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input.is-focus .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .url-input-field {
    flex: 1;

    ::v-deep .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.http_methods {
  border-radius: 4px 0 0 4px !important;
}

.send_button {
  width: 120px;
  margin-left: 10px;
  margin-top: 46px;
  background-color: var(--theme-color) !important;
  border: none;
  color: white;
}

.tabs ::v-deep .el-tabs__item.is-active {
  color: var(--theme-color) !important;
}

.tabs ::v-deep .el-tabs__item:hover {
  color: var(--theme-color) !important;
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: var(--theme-color) !important;
}

.variable-test-value {
  text-align: end;
  padding: 0 5px;
}

.ssl-cert {
  margin-top: 10px;
}

.ssl__row {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .ssl__inner-row {
    display: flex;
    flex: 1;
    align-items: center;
  }
}

.response {
  margin-top: 20px;

  ::v-deep .el-divider--horizontal {
    margin: 0 0 10px 0;
    background-color: #dadde2;
    height: 2px;
  }
}

.switcher {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inner-title {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 20px;
}

.pl-5 {
  padding-left: 5px;
}

.json-paths ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

::v-deep .new-item-or-select {
  .el-form-item {
    margin-bottom: 0;
  }
}

.test-row-for-url {
  color: #ababab;
  padding-top: 15px;
  font-size: 0.75rem;
  margin: -15px 0px 15px 0px !important;

  .http-method-col-test-row {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-weight: bold;
    width: 100px;
    height: 25px;
    margin: auto;
  }

  .test-url-col-test-row {
    padding-right: 0 !important;
    width: 100%;
  }

  ::v-deep .el-input__inner {
    height: 25px;
    border-color: #f5f5f5;
    font-size: 0.75rem;
    font-weight: bold;
    color: #ababab;
    background-color: #f5f5f5;
  }
}

.auth-button-group {
  margin-top: 10px;
  padding-bottom: 5px;
  height: 40px;
  display: flex;
  justify-content: space-between;

  .el-button {
    border: none;
    font-size: 14px;
    padding: 0 10px;
    color: black;
    background-color: #efefef;

    &:hover {
      color: white;
      background-color: var(--theme-hover-color);
    }

    &:focus,
    ::v-deep &.active {
      color: white;
      background-color: var(--theme-color);
    }
  }
}
</style>
