import Ajv from "ajv";
import addFormats from "ajv-formats";

/**
 * Set up AJV JSON Schema Validator
 */
const ajv = new Ajv({ allErrors: true, strict: true });
addFormats(ajv);

/**
 * Validates JSON
 * @param {string} content - JSON string
 * @returns {Object} Validation result { valid: boolean, error: string }
 */
export const validateJSON = content => {
  try {
    JSON.parse(content); // Basic JSON validation
    return { valid: true, error: "" };
  } catch (error) {
    return { valid: false, error: `Invalid JSON: ${error.message}` };
  }
};

/**
 * Validates XML/HTML structure
 * @param {string} content - XML/HTML string
 * @returns {Object} Validation result { valid: boolean, error: string }
 */
const validateXML = content => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "application/xml");

  if (doc.getElementsByTagName("parsererror").length > 0) {
    return { valid: false, error: "Invalid XML/HTML structure." };
  }
  return { valid: true, error: "" };
};

const validateHTML = content => {
  const parser = new DOMParser();

  // Convert named HTML entities to numeric equivalents
  const entityMap = {
    "&nbsp;": "&#160;", // Non-breaking space
    "&lt;": "&#60;", // Less than
    "&gt;": "&#62;", // Greater than
    "&amp;": "&#38;", // Ampersand
    "&quot;": "&#34;", // Double quote
    "&apos;": "&#39;" // Single quote
  };

  const sanitizedContent = content.replace(
    /&nbsp;|&lt;|&gt;|&amp;|&quot;|&apos;/g,
    match => entityMap[match]
  );

  const doc = parser.parseFromString(sanitizedContent, "application/xml");

  // If a parsing error occurs, `parsererror` will exist in the parsed document
  if (doc.getElementsByTagName("parsererror").length > 0) {
    return { valid: false, error: "Invalid HTML structure." };
  }

  return { valid: true, error: "" };
};

/**
 * Validates JavaScript code syntax
 * @param {string} content - JavaScript code
 * @returns {Object} Validation result { valid: boolean, error: string }
 */
const validateJavaScript = content => {
  try {
    new Function(content); // Checks for syntax errors
  } catch (error) {
    return { valid: false, error: `Invalid JavaScript: ${error.message}` };
  }

  const jsonValidation = validateJSONInsideJS(content);
  if (!jsonValidation.valid) {
    return jsonValidation;
  }

  const xmlValidation = validateXMLInsideJS(content);
  if (!xmlValidation.valid) {
    return xmlValidation;
  }

  const htmlValidation = validateHTMLInsideJS(content);
  if (!htmlValidation.valid) {
    return htmlValidation;
  }

  return { valid: true, error: "" };
};

/**
 * Extract and validate JSON inside JavaScript
 */
const validateJSONInsideJS = content => {
  const jsonPattern = /(["'`])(\s*\{.*?\}\s*|\s*\[.*?\]\s*)\1/g;
  const matches = [...content.matchAll(jsonPattern)];

  if (matches.length > 0) {
    for (const match of matches) {
      try {
        JSON.parse(match[2]); // Validate JSON structure
      } catch (error) {
        return {
          valid: false,
          error: `Invalid JSON inside JavaScript: ${error.message}`
        };
      }
    }
  }
  return { valid: true, error: "" };
};

const validateXMLInsideJS = content => {
  const xmlPattern = /(["'`])(\s*<\s*\w+[^>]*>[\s\S]*?)\1/g;
  const matches = [...content.matchAll(xmlPattern)];

  if (matches.length === 0) {
    return { valid: true, error: "" };
  }

  const parser = new DOMParser();

  for (const match of matches) {
    const xmlContent = match[2].trim();
    const doc = parser.parseFromString(xmlContent, "application/xml");

    if (doc.getElementsByTagName("parsererror").length > 0) {
      return {
        valid: false,
        error: "Invalid XML inside JavaScript: Malformed structure."
      };
    }

    const openTags = [...xmlContent.matchAll(/<(\w+)/g)].map(m => m[1]);
    const closeTags = [...xmlContent.matchAll(/<\/(\w+)/g)].map(m => m[1]);

    if (
      openTags.length !== closeTags.length ||
      !openTags.every(tag => closeTags.includes(tag))
    ) {
      return {
        valid: false,
        error: "Invalid XML inside JavaScript: Mismatched tags."
      };
    }
  }

  return { valid: true, error: "" };
};

/**
 * Extract and validate HTML inside JavaScript
 */
const validateHTMLInsideJS = content => {
  const htmlPattern = /(["'`])([^]*?<\w+[^]*?>.*?<\/\w+>[^]*?)\1/g;
  const matches = [...content.matchAll(htmlPattern)];
  if (matches.length === 0) {
    return { valid: true, error: "" };
  }
  const parser = new DOMParser();
  for (const match of matches) {
    const doc = parser.parseFromString(match[2].trim(), "text/html");
    if (doc.getElementsByTagName("parsererror").length > 0) {
      return {
        valid: false,
        error: "Invalid HTML inside JavaScript: Mismatched tags."
      };
    }
  }
  return { valid: true, error: "" };
};

/**
 * Validates plain text (Ensures no special control characters)
 * @param {string} content - Text content
 * @returns {Object} Validation result { valid: boolean, error: string }
 */
const validateText = content => {
  if (typeof content !== "string") {
    return { valid: false, error: "Invalid input type" };
  }

  const allowedControlChars = [10, 13];
  // Check for control characters using a safer approach
  if (
    [...content].some(char => {
      const code = char.charCodeAt(0);
      return code < 32 && !allowedControlChars.includes(code);
    })
  ) {
    return {
      valid: false,
      error: "Invalid text: Contains control characters."
    };
  }

  return { valid: true, error: "" };
};

/**
 * Main function to validate content type dynamically
 * @param {string} content - Input content
 * @param {string} type - Type of content ('json', 'xml', 'html', 'javascript', 'text')
 * @returns {Object} Validation result { valid: boolean, error: string }
 */
export const validateContent = (content, type) => {
  switch (type.toLowerCase()) {
    case "application/json":
      return validateJSON(content);
    case "application/xml":
      return validateXML(content);
    case "text/html":
      return validateHTML(content);
    case "application/javascript":
      return validateJavaScript(content);
    case "text/plain":
      return validateText(content);
    default:
      return { valid: false, error: "Unsupported content type." };
  }
};
